import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Post from "../components/post"
//import Image from "../components/image"
import { graphql } from "gatsby"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Img from "gatsby-image"


const IndexPage = ({data}) => {


    const description = "Poetry Books and other, from the UK poet Christian Lacdael";
    const tags = "Poetry Books UKpoet poems spokeword English";
    const publishDate = "2021-04-21";
    const img =  data.site.siteMetadata.url + data.site.siteMetadata.image;
    const imgHeight = data.site.siteMetadata.imgHeight;
    const imgWidth = data.site.siteMetadata.imgWidth;
    const url = data.site.siteMetadata.url;

    const schema = {
            "@context": "https://schema.org",
            "@type": "WebPage",
            "image": {
                "@type": "ImageObject",
                "url": img,
                "width": imgWidth,
                "height": imgHeight
            },
            "name": "Christian Lacdael Poetry",
            "url": url,
            "description": description,
            "datePublished": publishDate,
            "publisher": {
                "@type": "Organization",
                "@id": url+"#organization",
                "url": url,
                "name": "Christian Lacdael",
                "description": "UK poet Christian Lacdael",
                "logo": {
                    "@type": "ImageObject",
                    "@id": url+"#logo",
                    "url": img,
                    "width": imgWidth,
                    "height": imgHeight
                },
            },
            "keywords": tags,
            "author": {
                "@type": "Person",
                "name": "Christian Lacdael",
                "url": data.site.siteMetadata.url + "/christian-lacdael",
            }
        };

    return (
        <Layout>
        <SEO title="Home | Poetry.me" description={description} date={publishDate} image={img} tags={tags}  schemaMarkup={schema} />
        <div style={{padding:`2rem`}}>
        <Books data={data}/>
        </div>
        <hr/>
        <div style={{ margin:`3em auto`,display:`block`,width:`86%`,paddingBottom:`50%`,overflow:`hidden`,position:`relative` }}>
        <iframe title="recitals" style={{width:`100%`,height:`100%`,position:`absolute`}} src="https://www.youtube.com/embed/videoseries?list=PLwUVUmGvDVnnXozEFJLJRTu-iQlSGDTfI&enablejsapi=0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>
        <hr/>
        <Excerpts data={data}/>
        <div style={{ margin:`3em auto`,display:`block`,width:`92%`,position:`relative` }}>
        <BlogItem data={data} />
        </div>
        </Layout >
    );
}

const Books = ({ data }) => {
    const items = [];
    data.allMarkdownRemark.edges.map( (n) => {
        if (n.node.fields.slug.includes("books/") && n.node.frontmatter.icon) {
            if (items.length < 6) items.push(n);
        }
        return null;
    });

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1

    };
    return (
        <Slider {...settings} >
        { items && items.map( (n,i) =>
            <div className="showcase" key={i}>
            <Img style={{width:`50%`,float:`right`,marginLeft:`0.2rem`}} fluid={n.node.frontmatter.icon.childImageSharp.fluid} alt={n.node.frontmatter.title} />
            <h2>{n.node.frontmatter.title}</h2>
            <p>{n.node.excerpt}</p>
            <Link to={n.node.fields.slug}>Read More</Link>
            </div>
        )        }
        </Slider>
    );
}

const Excerpts = ({ data }) => {
    const items = [];
    data.allMarkdownRemark.edges.map( (n) => {
        if (n.node.fields.slug.includes("excerpts/")) {
            if (items.length < 6) items.push(n);
        }
        return null;
    });


    return (
        <div id="excerpts">
        { items && items.map( (n,i) =>
            <div key={i}><Link to={n.node.fields.slug}><Img fluid={n.node.frontmatter.icon.childImageSharp.fluid} alt={n.node.frontmatter.title} /></Link></div>
        )        }
        </div>
    );

}

const BlogItem = ({ data }) => {
    let item;
    data.allMarkdownRemark.edges.map( (n) => {
        if (n.node.fields.slug.includes("blog/")) {
            if (item) return null;
            else item = n;
        }
        return null;
    });

    return (
        <Post post={item} />
    );
};

//TODO: also get the sit logo and site url
// Get all markdown data, in descending order by date, and grab the id, excerpt, slug, date, and title
export const pageQuery = graphql`
  query {
    site {
        siteMetadata {
            url
            image
            imageHeight
            imageWidth
            title
        }
    }
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            icon {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid
                    }
                }
            }

        }
      }
    }
  }
}`;


// Get all markdown data, in descending order by date, and grab the id, excerpt, slug, date, and title

export default IndexPage

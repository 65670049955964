import { Link } from "gatsby"
import React from "react"
import Img from "gatsby-image"

const Post = ({ post }) => (
   <div className='post' key={post.node.id} style={{margin: `1em 0.5em`,overflow:`hidden`}}>
        <Link to={post.node.fields.slug}>
        { post.node.frontmatter.icon && <Img style={{float:`left`,width:`30vw`,marginRight:`1em`}} fluid={post.node.frontmatter.icon.childImageSharp.fluid} alt={post.node.title} /> }
        <div >
        <h2>{post.node.frontmatter.title}</h2>
        <em>{post.node.frontmatter.date}</em>
        <div style={{paddingLeft:0.5+`em`}}>
        <p>{post.node.excerpt}</p>
        </div>
        <span style={{color:`var(--notice)`}}>Read More</span>
        </div>
        </Link>
    </div>
)

export default Post
